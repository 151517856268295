/* dancing-script-400normal - latin */
@font-face {
  font-family: 'Dancing Script';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Dancing Script Regular '),
    local('Dancing Script-Regular'),
    url('./files/dancing-script-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/dancing-script-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* dancing-script-500normal - latin */
@font-face {
  font-family: 'Dancing Script';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Dancing Script Medium '),
    local('Dancing Script-Medium'),
    url('./files/dancing-script-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/dancing-script-latin-500.woff') format('woff'); /* Modern Browsers */
}

/* dancing-script-600normal - latin */
@font-face {
  font-family: 'Dancing Script';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Dancing Script SemiBold '),
    local('Dancing Script-SemiBold'),
    url('./files/dancing-script-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/dancing-script-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* dancing-script-700normal - latin */
@font-face {
  font-family: 'Dancing Script';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Dancing Script Bold '),
    local('Dancing Script-Bold'),
    url('./files/dancing-script-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/dancing-script-latin-700.woff') format('woff'); /* Modern Browsers */
}

